<template>
    <modal-lateral ref="modalLateralCrear" titulo="Seleccionar elemento">
        <div class="row mx-0 justify-center pt-5">
            <div class="col-10 options-create d-middle mb-3 cr-pointer" @click="crearElemento(1)">
                <i class="icon-title text-general" />
                <div class="col text-general f-18">
                    Título
                </div>
            </div>
            <div class="col-10 options-create d-middle mb-3 cr-pointer" @click="crearElemento(2)">
                <i class="icon-image-outline f-20 text-general" />
                <div class="col text-general f-18">
                    Imagenes
                </div>
            </div>
            <div class="col-10 options-create d-middle mb-3 cr-pointer" @click="crearElemento(3)">
                <i class="icon-category f-18 text-general" />
                <div class="col text-general f-18">
                    Categorías
                </div>
            </div>
            <div class="col-10 options-create d-middle cr-pointer" @click="crearElemento(4)">
                <i class="icon-product f-20 text-general" />
                <div class="col text-general f-18">
                    Productos
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        toggle(){
            this.$refs.modalLateralCrear.toggle();
        },
        crearElemento(typElement){
            this.$emit('crear-elemento', typElement);
        }
    }
}
</script>
<style lang="scss" scoped>
.options-create{
    height: 48px;border: 
    1px solid #DFE4E8;
    border-radius: 8px;
}
</style>